import React from 'react';

const SituationCard = ({ card, isBeginningGame, isEndGame }) => {
  return (
    <div 
    className={`situation-card ${isBeginningGame ? 'alone' : ''} ${isEndGame ? 'highlighted-red' : ''}`}
    >
      <div className="situation-card-image">
         <img src={process.env.PUBLIC_URL + "images/situation-cards/" + card.image} alt="Situation Card" />
      </div>
    </div>
  );
};

export default SituationCard;
