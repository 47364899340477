import React from 'react';

const ActionCard = ({ card }) => {
  return (
    <div className="situation-card actionCard">
      <div className="situation-card-image">
         <img src={process.env.PUBLIC_URL + "images/action-cards/" + card.image} alt="Action Card" />
      </div>
    </div>
  );
};

export default ActionCard;
