import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

const CategoryCard = ({ card, selected, onSelect, onExit, onPick, isEndGame }) => {
  const handleExit = () => {
    onExit();
  };
  const { t, i18n } = useTranslation();

  const parentContainerClass = selected ? 'category-card-container hidden' : 'category-card-container';
  const endGame = isEndGame ? 'highlighted-red-middle' : '';
  return (
    <div className={`${parentContainerClass} ${endGame}`}>
    {selected && <div className="overlay" />}
      <div
        className={`category-card ${selected ? 'selected' : ''}`}
      >

        <div className="category-card-content"  onClick={onSelect}>
          <img src={process.env.PUBLIC_URL + '/images/category-cards/' + card.image} alt="My Image" />
        </div>
        {selected && (
          <div className="card-buttons">
            <button className="exit-button" onClick={handleExit}>{t('exit')}</button>
            <button className="pick-button"  onClick={onPick}>{t('pick')}</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryCard;
