import React from 'react';

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <h1>404 - Page Not Found</h1>
      {/* Additional content for the error page */}
    </div>
  );
};

export default PageNotFound;
