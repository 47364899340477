import React, { useState, useEffect } from 'react';
import SituationCard from './SituationCard';
import CategoryCard from './CategoryCard';
import ActionCard from './ActionCard';

import englishActionCardsData from './data/englishActionCards.json';
import englishCategoryCardsData from './data/englishCategoryCards.json';
import englishSituationCardsData from './data/englishSituationCards.json';
import arabicActionCardsData from './data/arabicActionCards.json';
import arabicCategoryCardsData from './data/arabicCategoryCards.json';
import arabicSituationCardsData from './data/arabicSituationCards.json';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

const GameInterface = () => {
  const [rounds, setRounds] = useState(1);
  const [score, setScore] = useState(0);
  const [situationCards, setSituationCards] = useState(null);
  const [currentSituationCard, setCurrentSituationCard] = useState(null);
  const [remainingSituationCards, setRemainingSituationCards] = useState([]);
  const [currentRound, setCurrentRound] = useState(1);
  const [showMessage, setShowMessage] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  const [categoryCards, setCategoryCards] = useState([]);
  const [categoryCardsShuffled, setCategoryCardsShuffled] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [results, setResults] = useState([]);
  const [countdown, setCountdown] = useState(null); // Initial countdown value in seconds
  const [actionCardActive, setActionCardActive] = useState(false);
  const [actionCard, setActionCard] = useState();
  const [resultCards, setResultCards] = useState([]);

  const navigate = useNavigate();


  let timer;
  const { t, i18n } = useTranslation();

  const language = i18n.language;
  const actionCardsData = language === 'en' ? englishActionCardsData : arabicActionCardsData;
  const categoryCardsData = language === 'en' ? englishCategoryCardsData : arabicCategoryCardsData;
  const situationCardsData = language === 'en' ? englishSituationCardsData : arabicSituationCardsData;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Shuffle the category cards
    const shuffledCategoryCards = shuffleArray(categoryCardsData.cards);
    // Select the first 5 cards as the initial category cards
    const initialCategoryCards = shuffledCategoryCards.slice(0, 5);
    setCategoryCards(initialCategoryCards);
    setCategoryCardsShuffled(initialCategoryCards);
    const shuffledSituationCards = shuffleArray(situationCardsData.cards);
    setRemainingSituationCards(shuffledSituationCards);

    // Shuffle the action cards
    const shuffledActionCards = shuffleArray(actionCardsData.cards);
    setActionCard(shuffledActionCards[0]);
  }, []);

  const handleTimer = () => {
    if (rounds !== 5) {
      setCountdown(2) // Set the countdown duration (in seconds)
    }
    else {
      // Do something when the countdown ends
      setShowModal(false);
      setShowMessage(true);
      setSelectedCard(null);
      //setCurrentSituationCard(remainingSituationCards[currentRound - 1]);
      let totalRounds = 0;
      totalRounds = rounds + 1;
      setRounds(totalRounds);
      if (actionCardActive)
        setActionCardActive(false);
    }
    /*
    clearTimeout(timer); // Clear any existing timer
    setShowMessage(false); // Reset showMessage state
    timer = setTimeout(() => {
      setShowMessage(true);
      setSelectedCard(null);
      //setCurrentSituationCard(remainingSituationCards[currentRound - 1]);
      let totalRounds = 0;
      totalRounds = rounds + 1;
      setRounds(totalRounds);
    }, 3000);
    */
  };


  /*
    useEffect(() => {
      return () => clearTimeout(timer); // Clean up the timer on component unmount
    }, []); // Empty dependency array ensures the effect runs only once
  */


  const handleShuffleCategoryCardsWithNoDuplicate = () => {

    const shuffledCategoryCards = shuffleArray(categoryCardsData.cards);

    const oldArrayIds = resultCards.map(item => item.id);

    const newArray = shuffledCategoryCards.filter(item => !oldArrayIds.includes(item.id));

  }

  useEffect(() => {
    let timer;
    if (countdown !== null && countdown > 0) {
      if (rounds !== 3)
        setShowModal(true);
      timer = setTimeout(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000); // Update the countdown every 1 second
    } else if (countdown !== null) {
      // Do something when the countdown ends
      setShowModal(false);
      setShowMessage(true);
      setSelectedCard(null);
      //setCurrentSituationCard(remainingSituationCards[currentRound - 1]);
      let totalRounds = 0;
      totalRounds = rounds + 1;
      setRounds(totalRounds);
      if (actionCardActive)
        setActionCardActive(false);
    }

    return () => clearTimeout(timer); // Clean up the timer on component unmount or countdown reset
  }, [countdown]);

  useEffect(() => {
    if (rounds === 3) {
      setActionCardActive(true);
      let totalScore = score + actionCard.points;
      setScore(totalScore);
      setCountdown(5); // Set the countdown duration (in seconds)
    }
    if (rounds == 6) {
      setSelectedCard(categoryCards[categoryCards.length - 1]);
    }
  }, [rounds])

  useEffect(() => {
    if (selectedCard !== null) {
      //setResults([...results, selectedCard.type]);
    }
  }, [selectedCard]);

  // Function to shuffle an array using Fisher-Yates algorithm
  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const getRandomSituationCards = () => {
    const randomCards = [];
    for (let i = 0; i < 5; i++) {
      const cardIndex = Math.floor(Math.random() * remainingSituationCards.length);
      const randomCard = remainingSituationCards[cardIndex];
      randomCards.push(randomCard);
      setRemainingSituationCards(prevCards => prevCards.filter((_, index) => index !== cardIndex));
    }
    return randomCards;
  };


  useEffect(() => {
    if (remainingSituationCards.length === 0) {
      // Game ended, handle end of game logic

      setGameEnded(true);
      return;
    }

    setCurrentSituationCard(remainingSituationCards[rounds - 1]);
  }, [remainingSituationCards, rounds]);

  // Function to start the game and set initial cards
  const startGame = () => {
    // TODO: Set initial situation card and category cards
    // You can use setState or any other state management approach here
  };

  // Function to handle category card selection
  const handleCategoryCardSelect = (card) => {
    setSelectedCard(card);
  };

  const handleOnExit = () => {
    setSelectedCard(null);
  }

  const removeCard = (cardId) => {
    const toAddCardResult = categoryCards.find(card => card.id === cardId);

    // const updatedCards = categoryCards.filter((card) => card.id !== cardId);

    setResultCards([...resultCards, toAddCardResult]);

    const resultsWithRemoved = [...resultCards, cardId];

    const shuffledCategoryCards = shuffleArray(categoryCardsData.cards);

    const oldArrayIds = resultsWithRemoved.map(item => item.id);

    let newCardsToAdd = shuffledCategoryCards.filter(item => !oldArrayIds.includes(item.id));

    newCardsToAdd = newCardsToAdd.filter((card) => card.id !== selectedCard.id);

    let splicedRemainingCard;

    splicedRemainingCard = newCardsToAdd.splice(0, 5);

    // if (rounds <= 3)
    //   splicedRemainingCard = newCardsToAdd.splice(0, 5 - rounds);

    // else
    //   splicedRemainingCard = newCardsToAdd.splice(0, 5 - (rounds - 1));

    setCategoryCards(splicedRemainingCard);
  };

  const handleNewCategoryShuffle = () => {

  }

  const handleOnPick = () => {
    let totalScore = score + currentSituationCard.points;
    //setResults([...results, selectedCard.type]);
    setScore(totalScore);
    removeCard(selectedCard.id);
    //setRemainingSituationCards(remainingSituationCards.filter((card) => card.id  !== currentSituationCard.id));
    handleTimer();

    //setSelectedCard(null);
  }

  // Function to proceed to the next round
  const nextRound = () => {
    // TODO: Implement logic for next round
    // You can update rounds, score, situationCard, categoryCards, etc.
  };

  // Function to handle checking game results and navigate to results page
  const checkResults = () => {
    // TODO: Implement logic for checking game results
    // You can calculate the percentage of offensive categories based on the selected cards
    // Then navigate to the results page using history.push('/results');
  };

  const getRoundsScore = () => {
    return (
      <div className="rounds-score">
        <div className='outer-border-red'>
          <div className='rounds-score-inner-border'>
            <div className="rounds">
              <p>{t('round')} {rounds}</p>
            </div>
            <div className="score">
              <p>{score} {t('score')}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const getHandInterface = () => {
    return (
      <>
        <div className='pick-text'>
          <p>{t('pick_card_1')} <span className='least-span'>{t('least')}</span> {t('pick_card_2')}</p>
        </div><div className="category-cards">
          {categoryCards.map((card) => (
            <CategoryCard
              key={card.id}
              card={card}
              selected={selectedCard && selectedCard.id === card.id}
              onSelect={() => countdown > 0 ? null : handleCategoryCardSelect(card)}
              onExit={() => handleOnExit()}
              onPick={() => handleOnPick()} />
          ))}
        </div>
      </>
    );
  }

  const handleNav = () => {
    const data = {
      results: [...resultCards, selectedCard]
    };
    navigate('/results', { state: data });
  };


  const getResultInterface = () => {

    return (
      <>
        <div className='results-text'>
          <button className='button-results' onClick={handleNav}>
            {/* <Link to="/results">Check Results</Link> */}
            {t('check_results')}
          </button>

        </div>
      </>
    );
  }


  // Render the game interface
  return (
    <div className="game-interface">
      <div className="logo-container">
        <a href="/">
          <img src={process.env.PUBLIC_URL + 'images/say-it-logo.png'} alt="Logo" className="logo" />
          {/* <div className='circle-game-logo'></div> */}
        </a>
      </div>


      {getRoundsScore()}

      <div
        className={`deck ${rounds === 6 ? 'end-game' : ''}`}
      >


        {/* Render the current situation card */}
        {!actionCardActive && currentSituationCard && <SituationCard card={currentSituationCard} isBeginningGame={selectedCard === null} isEndGame={rounds === 6} />}
        {actionCardActive && <ActionCard card={actionCard} />}
        <div className='picked-card'>
          {selectedCard && <CategoryCard isSelectedCard={true} card={selectedCard} isEndGame={rounds === 6} />}
        </div>
        {/* <div className='countdown-container'>
       {countdown !== null && countdown > 0 && <h3> {t('next_round')}: {countdown}</h3>}
       </div> */}

        <div>
          <div className={`modal ${showModal ? 'show' : ''}`}>
            <img src={process.env.PUBLIC_URL + 'images/icon-arrow.png'} className='arrow-countdown-icon' />
            <h3 className='countdown-text'>{t('next_round')}</h3>
            {/* <h3 className='countdown-text'>{rounds === 6 ? t('next_round') : t('loading_result')}</h3> */}

            <span className='countdown-number'>{countdown}</span>
          </div>
          <div className={`overlay-model ${showModal ? 'show' : ''}`}></div>
        </div>

        {/**
       <div className="game-buttons">
        {rounds < 3 && (
      <button onClick={() => setCurrentRound(currentRound + 1)}>Next</button>
      )}
        {rounds === 3 && (
          <button onClick={checkResults}>Check Results</button>
        )}
      </div>
        **/}
      </div>
      <div>
        {rounds !== 6 ? getHandInterface() : getResultInterface()}
      </div>


    </div>
  );
};

export default GameInterface;
