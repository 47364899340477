import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './components/Home';
import GameInterface from './components/GameInterface';
import ResultsPage from './components/ResultsPage';
import Layout from './Layout';
import PageNotFound from './components/pageNotFound';

const App = () => {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />  
        <Route path="/game" element={<GameInterface />} />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="*"  element={<PageNotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;