import React from 'react';
import { Link } from 'react-router-dom';
import { Line } from 'rc-progress';
import { useLocation } from 'react-router-dom';
import PageNotFound from './pageNotFound';
import { useTranslation, Trans } from 'react-i18next';
import MultiProgress from "react-multi-progress";

function Progress(items) {
  const elements = items.map((item) => {
    return {
      value: item.value,
      color: item.color,
    };
  });

  return (
    <MultiProgress height={30} backgroundColor={"#E2E1E1"} elements={elements} />
  );
}

const percentagesRounds = [20, 15, 10, 12, 25];

const colorsArray = [
  {
    name: "islamophobia",
    color: "#E31732",
  },
  {
    name: "childism",
    color: "#252B68",
  },
  {
    name: "discrimination",
    color: "#E25A70",
  },
  {
    name: "homophobia",
    color: "#F23454",
  },
  {
    name: "religious_bigotry",
    color: "#B8C2D8",
  },
  {
    name: "sexism",
    color: "#9097A4"
  },
  {
    name: "classism",
    color: "#0820C1"
  },
  {
    name: "racism",
    color: "#6E7BD6"
  }
];

function getTypes(items) {
  const types = [];
  for (const item of items) {
    types.push(item.type);
  }
  return types;
}


function getTypesWithColors(items, colorsArray) {
  const typesWithColors = [];
  for (const item of items) {
    const typeColor = colorsArray.find((color) => color.name === item.type).color;
    typesWithColors.push({
      value: item.id,
      color: typeColor,
      name: item.type
    });
  }
  return typesWithColors;
}

function addPercentages(typesWithColors, percentagesRounds) {
  for (let i = 0; i < typesWithColors.length; i++) {
    typesWithColors[i]["value"] = percentagesRounds[i];
  }
  return typesWithColors;
}

const ResultSpectrumKeysContainer = (items) => {
  return (
    <div className="result-spectrum-keys-container">
      {items.map((item) => (
        <div className="spectrum-key" key={item.englishType}>
          <span className={`circle-key-circle ${item.englishType.toLowerCase()}`} />
          <span className="result-spectrum">{item.name}</span>
        </div>
      ))}
    </div>
  );
};

const ResultsPage = () => {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const cards = location?.state?.results;

  if (!cards) {
    return (
      <>
        <PageNotFound />
      </>
    )
  }



  const resultItems = addPercentages(getTypesWithColors(cards, colorsArray), percentagesRounds);

  const joinedItems = [];

  for (const item of resultItems) {
    const existingItem = joinedItems.find((joinedItem) => joinedItem.color === item.color);
    if (existingItem) {
      existingItem.value += item.value;
    } else {
      joinedItems.push(item);
    }
  }

  const joinedItemsTranslated = joinedItems.map((item) => {
    return {
      name: t(item.name),
      color: item.color,
      englishType: item.name
    };
  });

  return (
    // <div className="results-page">
    <div className={i18n.language === 'en' ? 'results-page english-class' : 'results-page other-language'}>

      <div className="center-column">
        <div className="result-item">
          <img src={process.env.PUBLIC_URL + 'images/say-it-logo.png'} alt="Logo" className="logo" />
          {/* <div className='circle-result'></div> */}
        </div>
        <div className="result-item">

          <p>{t('results')}</p>

        </div>
        <div className='progress-bar-container'>
          <div className='result-spectrum-container'>
            {/* <span className='result-spectrum small-text'>{t('spectrum_low')}</span> */}
           <span className={i18n.language === 'en' ? 'result-spectrum small-text' : 'result-spectrum'}>{t('spectrum_low')}</span>
            {/* <span className='result-spectrum'>{t('spectrum_high')}</span> */}
            <span className={i18n.language === 'en' ? 'result-spectrum ' : 'result-spectrum small-text'}>{t('spectrum_high')}</span>

          </div>
          <div className={i18n.language === 'en' ? 'progress-bar' : 'progress-bar transform-progress-bar'}>

          {Progress(joinedItems)}
          </div>
          {/* <div className='result-spectrum-keys-container'>
            <div className='spectrum-key'>
              <span className='circle-key-circle islamophobia'></span>
              <span className='result-spectrum'>{t('Islamophobia')}</span>
            </div>
            <div className='spectrum-key'>
              <span className='circle-key-circle childism'></span>
              <span className='result-spectrum'>{t('Childism')}</span>
            </div>
            <div className='spectrum-key'>
              <span className='circle-key-circle discrimination'></span>
              <span className='result-spectrum'>{t('Discrimination')}</span>
            </div>
            <div className='spectrum-key'>
              <span className='circle-key-circle homophobia'></span>
              <span className='result-spectrum'>{t('Homophobia')}</span>
            </div>
            <div className='spectrum-key'>
              <span className='circle-key-circle religious'></span>
              <span className='result-spectrum'>{t('ReligiousBigotry')}</span>
            </div>
          </div> */}
          {ResultSpectrumKeysContainer(joinedItemsTranslated)}
        </div>
        {/* <span className='result-spectrum-buy'>{t('result_text')}</span> */}
        <span className='result-spectrum-buy'>{t('result_text2')}</span>
        <span className='result-spectrum-buy'>{t('result_text3')}</span>
        <div className="buttons">
          <button className='pick-button'>
            <Link to="/game">{t('play_again')}</Link>
          </button>
          <button className='exit-button'>
            <Link to="/">{t('exit')}</Link>
          </button>
        </div>

        {/* <div className="result-item">
          <Line className='rc-progress-line-path' height={20} percent={100} strokeWidth={5} strokeColor="#BD1923" trailWidth={0}
            trailColor="#" />
        </div>
        <div className='results-percentage-container'>
        {CardPercentage(cards)}
        </div>
        <div className="result-item">
          <div className="buttons">
            <button className='pick-button'>
              <Link to="/game">{t('play_again')}</Link>
            </button>
            <button className='exit-button'>
              <Link to="/">{t('exit')}</Link>
            </button>
          </div>
        </div> */}
      </div>
    </div >
  );
};


const CardPercentage = (cards) => {

  const lineStyle = {
    height: '2px',
    backgroundColor: '#BD1923',
    width: '100px',
    marginLeft: '5px',
    marginRight: '5px'
  };

  // Count the occurrences of each type
  const typeCounts = cards.reduce((counts, card) => {
    const type = card.type.replace(/[-_]/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
    counts[type] = (counts[type] || 0) + 1;
    return counts;
  }, {});

  // Calculate the percentage for each type
  const totalCards = cards.length;
  const typePercentages = Object.entries(typeCounts).reduce((percentages, [type, count]) => {
    percentages[type] = (count / totalCards) * 100;
    return percentages;
  }, {});

  // Render the percentage for each type
  return (
    <div>
      {Object.entries(typePercentages).map(([type, percentage]) => (
        <div className="result-item-category"><p className='result-percentage' key={type}>{percentage.toFixed(2)}%</p>   <hr style={lineStyle} /> <p> {type}</p></div>
      ))}
    </div>
  );
};



export default ResultsPage;
