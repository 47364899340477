import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          start_game: "Start Game",
          welcome: 'Welcome to Say It!',
          begin: 'Click the start game button to begin',
          round: 'Round',
          score: 'Score',
          pick: 'Pick',
          exit: 'Exit',
          check_results: "Check Result",
          play_again: 'Play Again',
          pick_card: 'Pick the least offensive card',
          results: 'RESULTS',
          next_round: 'Next Round In',
          welcomeTo: "welcome to",
          welcome_line1: "TEST YOUR TOLERANCE FOR DIVERSITY!",
          welcome_line2: "ARE YOU TRUMP OR MOTHER THERESA?",
          welcome_line3: 'Click on the "Start Game" button to begin.',
          spectrum_low: "MOTHER THERESA",
          spectrum_high: "TRUMP",
          result_text: "Want more? Purchase the physical game on:",
          pick_card_1: "Pick the",
          pick_card_2: "offensive card",
          least: "least",
          result_text2: "Have fun confronting your worst prejudices and beating the hate out of your system!",
          result_text3: "Call us to get the full card game package at 01-499012- also available at",
          score: " PTS",
          islamophobia: "Islamophobia",
          childism: "Child-Hate",
          discrimination: "Discrimination",
          homophobia: "Homophobia",
          religious_bigotry: "Religious Bigotry",
          sexism: "Sexism",
          classism: "Snobbery",
          racism: "Racism",
          loading_result: "Loading Results"
        }
      },
      ar: {
        translation: {
          start_game: "بدء اللعبة",
          welcome: 'Say It! مرحباً بك في ',
          begin: '.اضغط على زر "بدء اللعبة" للبدء',
          round: 'جولة',
          score: 'النتيجة',
          pick: 'اختر',
          exit: 'خروج',
          check_results: "تحقق من النتائج",
          play_again: 'العب مرة أخرى',
          pick_card: 'اختر البطاقة الأقل حدة',
          results: 'النتائج',
          next_round: 'الجولة القادمة',
          welcomeTo: "مرحبًا بك في",
          welcome_line1: "!امتحن مدى احتمالك للاختلاف والتنوع",
          welcome_line2: " هل انت أقرب لدونالد ترامب أو للأم تيريزا؟",
          welcome_line3: '.اضغط على زر "بدء اللعبة" للبدء',
          islamophobia: "رهاب الإسلام",
          childism: "تمييز ضد الأطفال ",
          discrimination: "تمييز غير عادل",
          homophobia: "رهاب المثليين",
          religious_bigotry: "تعصب ديني ",
          sexism: "تحيز جنسي",
          classism: "تمييز الطبقات ",
          racism: "عنصرية",
          pick_card_1: "اختر البطاقة",
          pick_card_2: " إساءة",
          least: "الأقل",
          result_text2: "واجه أسوأ ما في تفكيرك من أحكام مسبقة وصور نمطية، وحرر نفسك من الكراهية، في جو من المرح والضحك!",
          result_text3: "يمكنكم الحصول على اللعبة الكاملة عبر الاتصال على ٠١/٤٩٩٠١٢ أو في المحلات التالية:",
          score: " نقاط",
          spectrum_low: "ترامب",
          spectrum_high: "أم تيريزا ",
          loading_result: "تحميل النتائج"

        }
      }
    }
  });

export default i18n;