import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

const lngs = {
  en: { nativeName: 'English', start_game: 'Start Game' },
  ar: { nativeName: 'Arabic' }
};

const Home = () => {
  const { t, i18n } = useTranslation();

  return (
    
    // <div className="home">
      <div className={i18n.language === 'en' ?  'home english-class': 'home other-language'}>

      <div className="logo-container">
        <img src={process.env.PUBLIC_URL + 'images/say-it-logo.png'} alt="Logo" className="logo" />
      </div>
      <div className='language-btns'>
        {Object.keys(lngs).map((lng) => (
          <button className='btn btn-language' key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
            {lngs[lng].nativeName}
          </button>
        ))}
      </div>
      <span className='welcome-text'>{t('welcomeTo')}</span>
      <div className="welcome-container">
        <img src={process.env.PUBLIC_URL + 'images/say-it-logo.png'} alt="Logo" className="logo-welcome" />
        <div className='welcome-separator'>
        <span className='welcome-text'>{t('welcome_line1')}</span>
        <span className='welcome-text'>{t('welcome_line2')}</span>
        </div>
        <span className='welcome-small'>{t('welcome_line3')}</span>

      </div>
      <div className="start-game-button">
        <Link to="/game" className="btn btn-primary">{t('start_game')}</Link>
      </div>
      {/* <div className="description">
        <p> {t('welcome')}</p>
        <p>{t('begin')}</p>
      </div> */}
    </div>
  );
};

export default Home;
